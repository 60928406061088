(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/upcoming-events/assets/javascripts/use-get-tournament-events.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/upcoming-events/assets/javascripts/use-get-tournament-events.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  useState,
  useEffect,
  useRef
} = React;
const {
  jupiter,
  log
} = svs.core;
const {
  TournamentFetchMoreCount
} = svs.poker_react.components.common.constants;
const logger = log.getLogger('module:poker_react:components:upcoming_events:get_tournaments');
const fetchTournaments = async (activeFilters, offset) => {
  const qs = new URLSearchParams();
  if (activeFilters.tournamentType === 'poker-sm') {
    qs.append('PokerSMType', activeFilters.satellite);
  } else {
    if (activeFilters.gameType !== null) {
      qs.append('gameType', activeFilters.gameType);
    }
    if (activeFilters.buyin.id !== null) {
      qs.append('minTotalBuyIn', activeFilters.buyin.min);
      qs.append('maxTotalBuyIn', activeFilters.buyin.max);
    }
  }
  qs.append('offset', offset);
  qs.append('count', offset === 0 ? 5 : TournamentFetchMoreCount);
  try {
    const {
      response
    } = await jupiter.callAsync({
      method: 'GET',
      path: "/poker/2/tournaments/".concat(activeFilters.tournamentType, "/upcoming?").concat(qs.toString())
    });
    return {
      moreItems: response.moreItems,
      tournaments: response.tournaments.map(tournament => _objectSpread(_objectSpread({}, tournament), {}, {
        tournamentId: tournament.id,
        buyIn: tournament.totalBuyIn
      }))
    };
  } catch (error) {
    logger.error("Error fetching poker events: ".concat(error));
    return {
      error
    };
  }
};
const useGetTournamentEvents = (activeFilters, offset) => {
  const [tournaments, setTournaments] = useState([]);
  const [moreItems, setMoreItems] = useState(false);
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [error, setError] = useState(null);
  const prevOffset = useRef(null);
  useEffect(() => {
    const isFetchMore = prevOffset.current !== offset && offset > 0;
    if (isFetchMore) {
      setIsFetchingMore(true);
    } else {
      prevOffset.current = 0;
      setShouldShowSkeleton(true);
    }
    async function fetchData() {
      setError(null);
      const results = await fetchTournaments(activeFilters, offset);
      if (results.error) {
        setError(results.error);
        setTournaments([]);
        setMoreItems(false);
        setShouldShowSkeleton(false);
        return;
      }
      let newTournaments = results.tournaments;
      if (isFetchMore) {
        newTournaments = [...tournaments, ...results.tournaments];
        prevOffset.current = offset;
      }
      setTournaments(newTournaments);
      setMoreItems(results.moreItems);
      if (isFetchMore) {
        setIsFetchingMore(false);
      } else {
        setShouldShowSkeleton(false);
      }
    }
    fetchData();
  }, [activeFilters, offset]);
  return {
    tournaments,
    moreItems,
    shouldShowSkeleton,
    error,
    isFetchingMore
  };
};
setGlobal('svs.poker_react.components.upcomingEvents.useGetTournamentEvents', useGetTournamentEvents);

 })(window);