(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/upcoming-events/assets/javascripts/use-formatted-date-time.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/upcoming-events/assets/javascripts/use-formatted-date-time.js');
"use strict";


const {
  useEffect,
  useState
} = React;
const {
  formatDate
} = svs.components.lbUtils.helpers.formatDate;

const useFormattedDateTime = _ref => {
  let {
    startTime,
    registrationEndTime,
    isLateReg
  } = _ref;
  const [dateLabel, setDateLabel] = useState('');
  const [timeLabel, setTimeLabel] = useState('');
  useEffect(() => {
    let interval;
    if (isLateReg) {
      setDateLabel('Sen reg');
      const minutesLeft = dateFns.differenceInMinutes(new Date(registrationEndTime), new Date());
      setTimeLabel("".concat(minutesLeft, " min"));
      interval = setInterval(() => {
        const minutesLeft = dateFns.differenceInMinutes(new Date(registrationEndTime), new Date());
        if (minutesLeft > 0) {
          setTimeLabel("".concat(minutesLeft, " min"));
        } else {
          setTimeLabel('Startar nu');
        }
      }, 1000);
    } else {
      const minutesLeft = dateFns.differenceInMinutes(new Date(startTime), new Date());
      if (minutesLeft < 60) {
        setDateLabel('Startar om');
        setTimeLabel("".concat(minutesLeft, " min"));
        interval = setInterval(() => {
          const minutesLeft = dateFns.differenceInMinutes(new Date(startTime), new Date());
          if (minutesLeft > 0) {
            setTimeLabel("".concat(minutesLeft, " min"));
          } else {
            setDateLabel('Startar');
            setTimeLabel('nu');
          }
        }, 1000);
      } else {
        setDateLabel(formatDate(new Date(startTime), false, true, true, true));
        setTimeLabel(dateFns.format(new Date(startTime), 'HH:mm'));
      }
    }
    return () => clearInterval(interval);
  }, [startTime, registrationEndTime, isLateReg]);
  return {
    dateLabel,
    timeLabel
  };
};
setGlobal('svs.poker_react.components.upcomingEvents.useFormattedDateTime', useFormattedDateTime);

 })(window);