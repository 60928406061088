(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/upcoming-events/assets/javascripts/filter-modal.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/upcoming-events/assets/javascripts/filter-modal.js');
"use strict";

const {
  useState
} = React;
const {
  Modal
} = svs.components.lbUi.modal;
const {
  ReactButton: Button,
  ReactIcon: Icon
} = svs.ui;
const {
  BuyinFilter,
  GameTypeFilter,
  TournamentTypeFilter,
  SatelliteFilter
} = svs.poker_react.components.upcomingEvents;
const {
  actionTypes
} = svs.poker_react.components.upcomingEvents.filterReducer;
const FilterModal = _ref => {
  let {
    activeFilters,
    dispatch,
    isPokerSM
  } = _ref;
  const [showModal, setShowModal] = useState(false);
  const renderFilters = () => {
    if (isPokerSM) {
      return React.createElement(SatelliteFilter, {
        activeSatelliteFilter: activeFilters.satellite,
        setActiveSatelliteFilter: filter => dispatch({
          type: actionTypes.SET_SATELLITE_FILTER,
          payload: filter
        })
      });
    }
    return React.createElement(React.Fragment, null, React.createElement(GameTypeFilter, {
      activeGameTypeFilter: activeFilters.gameType,
      setActiveGameTypeFilter: filter => dispatch({
        type: actionTypes.SET_GAME_TYPE_FILTER,
        payload: filter
      })
    }), React.createElement(BuyinFilter, {
      activeBuyinFilter: activeFilters.buyin,
      isNetwork: activeFilters.tournamentType === 'network',
      setActiveBuyinFilter: filter => dispatch({
        type: actionTypes.SET_BUY_IN_FILTER,
        payload: filter
      })
    }));
  };
  return React.createElement(React.Fragment, null, React.createElement(Button, {
    block: true,
    className: "btn-mobile-filter",
    iconPosition: "center",
    onClick: () => setShowModal(true)
  }, React.createElement(Icon, {
    icon: "filter"
  }), React.createElement("span", {
    className: "btn-text"
  }, "Filter")), React.createElement(Modal, {
    className: "mobile-filter-container",
    isOpen: showModal
  }, React.createElement("div", {
    className: "mobile-filter-controls"
  }, React.createElement("h2", {
    className: "f-content f-center h2 fc-white"
  }, "Filter"), React.createElement(TournamentTypeFilter, {
    activeTournamentFilter: activeFilters.tournamentType,
    setTournamentFilter: filter => dispatch({
      type: actionTypes.SET_TOURNAMENT_FILTER,
      payload: filter
    })
  }), renderFilters()), React.createElement(Button, {
    className: "btn-mobile-filter-close btn-poker",
    onClick: () => setShowModal(false)
  }, "Klar")));
};
setGlobal('svs.poker_react.components.upcomingEvents.FilterModal', FilterModal);

 })(window);