(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/upcoming-events/assets/javascripts/game-type-filter.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/upcoming-events/assets/javascripts/game-type-filter.js');
"use strict";


const {
  ReactDropdown: {
    DropdownSelectItem,
    DropdownItem,
    DropdownLabel
  }
} = svs.ui;
const {
  GameTypes
} = svs.poker_react.components.common.constants;
const GameTypeFilter = _ref => {
  let {
    setActiveGameTypeFilter,
    activeGameTypeFilter
  } = _ref;
  return React.createElement("div", {
    className: "poker-filter-dropdown"
  }, React.createElement(DropdownSelectItem, {
    branding: "poker-react",
    onSelect: selected => setActiveGameTypeFilter(selected),
    selectLabel: "Speltyp",
    v2: true
  }, GameTypes.map(gameType => React.createElement(DropdownItem, {
    key: gameType.id,
    name: gameType.id,
    selected: activeGameTypeFilter === gameType.id
  }, React.createElement(DropdownLabel, null, gameType.title)))));
};
setGlobal('svs.poker_react.components.upcomingEvents.GameTypeFilter', GameTypeFilter);

 })(window);