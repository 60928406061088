(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/upcoming-events/assets/javascripts/satellite-filter.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/upcoming-events/assets/javascripts/satellite-filter.js');
"use strict";


const {
  ReactDropdown: {
    DropdownSelectItem,
    DropdownItem,
    DropdownLabel
  }
} = svs.ui;
const {
  SatelliteFilters
} = svs.poker_react.components.common.constants;
const SatelliteFilter = _ref => {
  let {
    setActiveSatelliteFilter,
    activeSatelliteFilter
  } = _ref;
  return React.createElement("div", {
    className: "poker-filter-dropdown"
  }, React.createElement(DropdownSelectItem, {
    branding: "poker-react",
    onSelect: selected => setActiveSatelliteFilter(selected),
    selectLabel: "Turneringar",
    v2: true
  }, SatelliteFilters.map(option => React.createElement(DropdownItem, {
    key: option.label,
    name: option.value,
    selected: activeSatelliteFilter === option.value
  }, React.createElement(DropdownLabel, null, option.label)))));
};
setGlobal('svs.poker_react.components.upcomingEvents.SatelliteFilter', SatelliteFilter);

 })(window);