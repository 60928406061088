(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/upcoming-events/assets/javascripts/tournament-list-item.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/upcoming-events/assets/javascripts/tournament-list-item.js');
"use strict";


const {
  ReactIcon: Icon
} = svs.ui;
const {
  Currency: formatCurrency
} = svs.utils.format;
const {
  useFormattedDateTime
} = svs.poker_react.components.upcomingEvents;
const TournamentListItem = _ref => {
  let {
    tournamentId,
    registrationEndTime,
    name,
    buyIn,
    registrationCount,
    onClick,
    startTime,
    isLateReg,
    currency
  } = _ref;
  const {
    timeLabel,
    dateLabel
  } = useFormattedDateTime({
    registrationEndTime,
    startTime,
    isLateReg
  });
  const buyInString = currency === 'SEK' ? "".concat(formatCurrency(buyIn), " kr") : "\u20AC".concat(formatCurrency(buyIn));
  return React.createElement("div", {
    className: "tournament-list-item",
    key: tournamentId,
    onClick: () => onClick(tournamentId, name)
  }, React.createElement("div", {
    className: "date-container fc-poker-100"
  }, React.createElement("div", null, dateLabel), React.createElement("div", {
    className: "f-bold"
  }, timeLabel)), React.createElement("div", {
    className: "info-container"
  }, React.createElement("div", null, React.createElement("div", {
    className: "f-bold fc-poker-100"
  }, name), React.createElement("div", {
    className: "fc-poker-200"
  }, "Ink\xF6p ".concat(buyInString, " | ").concat(registrationCount, " anm\xE4lda"))), React.createElement(Icon, {
    className: "arrow-icon",
    color: "fc-poker-200",
    icon: "menu-right"
  })));
};
setGlobal('svs.poker_react.components.upcomingEvents.TournamentListItem', TournamentListItem);

 })(window);