(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/upcoming-events/assets/javascripts/tournament-type-filter.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/upcoming-events/assets/javascripts/tournament-type-filter.js');
"use strict";

var _svs$poker_react$data;

const {
  RadioGroup,
  RadioButton
} = svs.ui.reactForm;
const {
  TournamentFilters
} = svs.poker_react.components.common.constants;
let renderFilters;
if (!((_svs$poker_react$data = svs.poker_react.data.upcomingEventsData) !== null && _svs$poker_react$data !== void 0 && _svs$poker_react$data.pokerSMVisible)) {
  renderFilters = TournamentFilters.filter(gameType => gameType.id !== 'poker-sm');
} else {
  renderFilters = TournamentFilters.filter(gameType => gameType.id !== 'poy');
}
const TournamentTypeFilter = _ref => {
  let {
    activeTournamentFilter,
    setTournamentFilter
  } = _ref;
  return React.createElement(RadioGroup, {
    className: "filter-btn-container",
    size: 200
  }, renderFilters.map(filter => React.createElement(RadioButton, {
    branding: "poker-react",
    checked: filter.id === activeTournamentFilter,
    key: filter.id,
    onChange: () => setTournamentFilter(filter.id),
    value: filter.id
  }, filter.title)));
};
setGlobal('svs.poker_react.components.upcomingEvents.TournamentTypeFilter', TournamentTypeFilter);

 })(window);