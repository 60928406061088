(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/upcoming-events/assets/javascripts/filter-reducer.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/upcoming-events/assets/javascripts/filter-reducer.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  BuyinFiltersSEK,
  BuyinFiltersEUR,
  SatelliteFilters
} = svs.poker_react.components.common.constants;
const SET_TOURNAMENT_FILTER = 'SET_TOURNAMENT_FILTER';
const SET_GAME_TYPE_FILTER = 'SET_GAME_TYPE_FILTER';
const SET_BUY_IN_FILTER = 'SET_BUY_IN_FILTER';
const SET_SATELLITE_FILTER = 'SET_SATELLITE_FILTER';
const filterReducer = (state, action) => {
  switch (action.type) {
    case SET_TOURNAMENT_FILTER:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          tournamentType: action.payload,
          buyin: action.payload === 'network' ? BuyinFiltersEUR[0] : BuyinFiltersSEK[0],
          satellite: SatelliteFilters[0].value
        });
      }
    case SET_GAME_TYPE_FILTER:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          gameType: action.payload
        });
      }
    case SET_BUY_IN_FILTER:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          buyin: action.payload
        });
      }
    case SET_SATELLITE_FILTER:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          satellite: action.payload
        });
      }
    default:
      return state;
  }
};
setGlobal('svs.poker_react.components.upcomingEvents.filterReducer', {
  reducer: filterReducer,
  actionTypes: {
    SET_TOURNAMENT_FILTER,
    SET_GAME_TYPE_FILTER,
    SET_BUY_IN_FILTER,
    SET_SATELLITE_FILTER
  }
});

 })(window);