(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/upcoming-events/assets/javascripts/buyin-filter.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/upcoming-events/assets/javascripts/buyin-filter.js');
"use strict";


const {
  ReactDropdown: {
    DropdownSelectItem,
    DropdownItem,
    DropdownLabel
  }
} = svs.ui;
const {
  BuyinFiltersSEK,
  BuyinFiltersEUR
} = svs.poker_react.components.common.constants;
const BuyinFilter = _ref => {
  let {
    activeBuyinFilter,
    isNetwork,
    setActiveBuyinFilter
  } = _ref;
  const filters = isNetwork ? BuyinFiltersEUR : BuyinFiltersSEK;
  return React.createElement("div", {
    className: "poker-filter-dropdown"
  }, React.createElement(DropdownSelectItem, {
    branding: "poker-react",
    onSelect: selected => setActiveBuyinFilter(JSON.parse(selected)),
    selectLabel: "Ink\xF6p",
    v2: true
  }, filters.map(filter => React.createElement(DropdownItem, {
    key: filter.id,
    name: JSON.stringify(filter),
    selected: activeBuyinFilter.id === filter.id
  }, React.createElement(DropdownLabel, null, filter.title)))));
};
setGlobal('svs.poker_react.components.upcomingEvents.BuyinFilter', BuyinFilter);

 })(window);