(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/upcoming-events/assets/javascripts/skeleton.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/upcoming-events/assets/javascripts/skeleton.js');
"use strict";


const SkeletonLine = svs.components.lbUi.SkeletonLine;
const Skeleton = () => React.createElement(React.Fragment, null, React.createElement(SkeletonLine, {
  className: "highlighted-card-skeleton"
}), React.createElement(SkeletonLine, {
  className: "tournament-list-item-skeleton",
  height: 2
}), React.createElement(SkeletonLine, {
  className: "tournament-list-item-skeleton",
  height: 2
}), React.createElement(SkeletonLine, {
  className: "tournament-list-item-skeleton",
  height: 2
}), React.createElement(SkeletonLine, {
  className: "tournament-list-item-skeleton",
  height: 2
}));
setGlobal('svs.poker_react.components.upcomingEvents.Skeleton', Skeleton);

 })(window);