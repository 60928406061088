(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/upcoming-events/assets/javascripts/highlighted-card.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/upcoming-events/assets/javascripts/highlighted-card.js');
"use strict";


const {
  Currency: formatCurrency
} = svs.utils.format;
const {
  ReactButton: Button,
  ReactRibbon: {
    RibbonBanner
  }
} = svs.ui;
const {
  useFormattedDateTime
} = svs.poker_react.components.upcomingEvents;
const HighlightedCard = _ref => {
  let {
    tournamentId,
    registrationEndTime,
    name,
    buyIn,
    registrationCount,
    onRegistrationClick,
    startTime,
    isLateReg,
    currency
  } = _ref;
  const {
    timeLabel,
    dateLabel
  } = useFormattedDateTime({
    registrationEndTime,
    startTime,
    isLateReg
  });
  const buyInString = currency === 'SEK' ? "".concat(formatCurrency(buyIn), " kr") : "\u20AC".concat(formatCurrency(buyIn));
  return React.createElement("div", {
    className: "highlighted-card",
    key: tournamentId
  }, React.createElement(RibbonBanner, {
    className: "poker-ribbon",
    direction: "left",
    offsetTop: 24,
    size: "400"
  }, isLateReg ? "Sen reg ".concat(timeLabel) : "".concat(dateLabel, " ").concat(timeLabel)), React.createElement("div", {
    className: "info-container"
  }, React.createElement("h2", {
    className: "f-content h2"
  }, name), React.createElement("p", {
    className: "f-content p"
  }, "Ink\xF6p ".concat(buyInString), React.createElement("br", null), "".concat(registrationCount, " anm\xE4lda"))), React.createElement(Button, {
    className: "btn-poker",
    onClick: () => onRegistrationClick(tournamentId, name)
  }, "Anm\xE4lan"));
};
setGlobal('svs.poker_react.components.upcomingEvents.HighlightedCard', HighlightedCard);

 })(window);